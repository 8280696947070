import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import Script from "next/script";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-phone-number-input/style.css";
import "react-loading-skeleton/dist/skeleton.css";
import "sweetalert2/src/sweetalert2.scss";
import "@/styles/globals.scss";
import React, { Fragment, useEffect } from "react";
import { RecoilRoot } from "recoil";
// import Header from "@/layouts/HeaderBlock";
// import Wrapper from "@/components/Wrapper";
// import SubscribeNowModal from "@/components/Subscribemodals/SubscribeNowModal";
// import Footer from "@/layouts/Footer";
// import apiConfig from "@/config/config";
import "react-datepicker/dist/react-datepicker.css";
import "@dotlottie/react-player/dist/index.css";

const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  // const { cloudFrontUrl } = apiConfig;
  const isComingSoonPage = router.pathname === "/coming-soon";

  useEffect(() => {
    if (isComingSoonPage) {
      document.querySelector("body").classList.add("comingSoonPage");
    }
  }, [router]);

  useEffect(() => {
    if (router?.pathname === `/`) {
      router?.push(`/coming-soon`);
    }
  }, [router]);

  useEffect(() => {
    const handleRouteChangeForGa4 = (url: any) => {
      if (typeof window !== undefined) {
        (window as any)?.gtag("config", process.env.NEXT_PUBLIC_GA4_ID, {
          page_path: url,
        });
      }
    };

    // Subscribe to the change event
    router.events.on("routeChangeComplete", handleRouteChangeForGa4);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChangeForGa4);
    };
  }, [router.events, router.asPath]);

  useEffect(() => {
    const handleRouteChangeForGa = (url: any) => {
      if (typeof window !== undefined) {
        (window as any)?.gtag("config", process.env.NEXT_PUBLIC_GA_ID, {
          page_path: url,
        });
      }
    };

    // Subscribe to the change event
    router.events.on("routeChangeComplete", handleRouteChangeForGa);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChangeForGa);
    };
  }, [router.events]);

  // const [modal, setModal] = React.useState(false);
  // const backdrop = true;
  // const keyboard = true;
  // const className = "subscribe_home";

  // React.useEffect(() => {
  //   const ismodal = localStorage?.getItem("isModal");
  //   if (JSON.parse(ismodal) !== "false") {
  //     setTimeout(() => {
  //       const ismodal = localStorage?.getItem("isModal");
  //       if (JSON.parse(ismodal) !== "false") {
  //         setModal(true);
  //       }
  //     }, 20000);
  //   }
  // }, []);

  return (
    <Fragment>
      <>
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM}`}
            height="0"
            width="0"
            title="googleTagManagerNoScript"
            style={{ display: "none", visibility: "hidden" }}
          ></iframe>
        </noscript>
        {/* <!-- Google Tag Manager --> */}
        <Script
          async
          id="google-tag-manager"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `(function (w, d, s, l, i) {
                  w[l] = w[l] || [];
                  w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
                  var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s),
                    dl = l != "dataLayer" ? "&l=" + l : "";
                  j.async = true;
                  j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
                  f.parentNode.insertBefore(j, f);
                })(window, document, "script", "dataLayer", '${process.env.NEXT_PUBLIC_GTM}');`,
          }}
        />

        {/* <!-- Google Analytics tag (gtag.js) --> */}
        <Script
          defer
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_ID}`}
          strategy="afterInteractive"
        />
        <Script defer id="google-analytics" strategy="afterInteractive">
          {`
             window.dataLayer = window.dataLayer || [];
             function gtag() {
               dataLayer.push(arguments);
             }
             gtag("js", new Date());

             gtag("config", '${process.env.NEXT_PUBLIC_GA_ID}');
          `}
        </Script>
      </>

      <Script
        defer
        async
        id="bootstrap"
        strategy="lazyOnload"
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/js/bootstrap.min.js"
        crossOrigin="anonymous"
      />
      <RecoilRoot>
        {/* <SubscribeNowModal
          modal={modal}
          setModal={setModal}
          className={className}
          backdrop={backdrop}
          keyboard={keyboard}
        /> */}
        {/* <Wrapper> */}
        {/* {!isComingSoonPage && <Header cloudFrontUrl={cloudFrontUrl} />} */}
        {/* </Wrapper> */}
        <Component key={router.asPath} {...pageProps} />
        {/* {!isComingSoonPage && <Footer />} */}
      </RecoilRoot>
    </Fragment>
  );
};

export default App;
